import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { AuthService } from '@src/app/auth/services/auth.service';
import { distinctUntilChanged, map, tap } from 'rxjs';

export const authGuard: CanMatchFn = (route, state) => {
	const authService = inject(AuthService);

	return authService.isLoggedIn$.pipe(
		distinctUntilChanged(),
		map((isLoggedIn) => isLoggedIn),
		tap((isLoggedIn) => !isLoggedIn && authService.logout())
	);
};
