import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { HasUnsavedChanges, IAlert } from '../interfaces';
import { ToasterService } from '../services';
import { AlertUnsavedChanges } from '../constants';

export const withoutUnsavedChangesGuard: CanDeactivateFn<HasUnsavedChanges> = (
	component
) => {
	const toasterService = inject(ToasterService);

	if (!component.hasUnsavedChanges()) {
		return true;
	}

	return toasterService
		.confirmAlert(AlertUnsavedChanges)
		.then(({ isConfirmed }) => {
			return isConfirmed;
		});
};
